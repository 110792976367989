<template>

  <div>
    <b-modal
      id="records-modal"
      ref="records-modal"
      :title="'Diğer Gider Kayıtları '+targetYear+'-'+$staticParams.getMonthName(targetMonth)"
      ok-only
      hide-footer
      size="xl"
    >
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('Gösterilen') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t('Kayıtlar') }}</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />

              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0 "
            >
              <b-button
                variant="primary"
                size="sm"
                @click="toExcel"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span class="text-nowrap">{{ $t('Tümünü Excel Olarak İndir') }}</span>
              </b-button>
              <b-button
                v-if="$can('read', 'product_edit')"
                id="import-modal-toggle-btn"
                v-b-modal.import-modal
                variant="dark"
                size="sm"
              >
                <feather-icon
                  icon="UploadIcon"
                  class="mr-50"
                />
                <span class="text-nowrap">{{ $t("Excel'den İçer Aktar") }}</span>
              </b-button>

            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refProductListTable"
          class="position-relative"
          :items="fetchProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          small
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        > <template #cell(list_price)="data">
            {{ $staticParams.formatCurrency(data.item.list_price,4) }}
          </template>
          <template #cell(currency_unit_price)="data">
            {{ $staticParams.formatCurrency(data.item.currency_unit_price,4) }}
          </template>
          <template #cell(tl_unit_price)="data">
            {{ $staticParams.formatCurrency(data.item.tl_unit_price,4) }}
          </template>
          <template #cell(net_currency_unit)="data">
            {{ $staticParams.formatCurrency(data.item.net_currency_unit,4) }}
          </template>
          <template #cell(currency_invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.currency_invoice_total,4) }}
          </template>
          <template #cell(tl_invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.tl_invoice_total,4) }}
          </template>
          <template #cell(net_currency_total)="data">
            {{ $staticParams.formatCurrency(data.item.net_currency_total,4) }}
          </template>
          <template #cell(net_tl_total)="data">
            {{ $staticParams.formatCurrency(data.item.net_tl_total,4) }}
          </template>
          <template #cell(currency_rebate)="data">
            {{ $staticParams.formatCurrency(data.item.netcurrency_rebate_tl_total,4) }}
          </template>
          <template #cell(tl_rebate)="data">
            {{ $staticParams.formatCurrency(data.item.tl_rebate,4) }}
          </template>
          <template #cell(invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.invoice_total,4) }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalProducts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-modal>
    <b-modal
      id="import-modal"
      ref="import-modal"
      title="Excel'den Diğer Giderler Aktar"
      :ok-title="$t('İçe Aktarmayı Başlat')"
      :cancel-title="$t('Vazgeç')"
      :hide-footer="hideModalFooter"
      size="xl"
      @show="resetImportModal"
      @hidden="resetImportModal"
      @ok="startImport"
    >
      <b-overlay
        :show="importModalOverlayShow"
        rounded="sm"
      >

        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body" />
        </b-alert>
        <b-form-group
          :label="$t('Excel Dosyası')"
          label-for="image_file_path"
        >
          <b-form-file
            id="image_file_path"
            accept=".xls, .xlsx"
            :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
            :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
            @change="onFileChange"
          />

        </b-form-group>
        <span v-if="uploadFailedRows.length>0"><strong>{{ uploadFailedRows.length }}</strong> Adet Satırın Yüklenmesi Başarız Oldu. Başarısız olan satırlar aşağıda listelenmiştir</span>
        <b-table
          v-if="uploadFailedRows.length>0"
          ref="refProductListTable"
          class="position-relative"
          :items="uploadFailedRows"
          responsive
          :fields="importTableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          small
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(list_price)="data">
            {{ $staticParams.formatCurrency(data.item.list_price,4) }}
          </template>
          <template #cell(currency_unit_price)="data">
            {{ $staticParams.formatCurrency(data.item.currency_unit_price,4) }}
          </template>
          <template #cell(tl_unit_price)="data">
            {{ $staticParams.formatCurrency(data.item.tl_unit_price,4) }}
          </template>
          <template #cell(net_currency_unit)="data">
            {{ $staticParams.formatCurrency(data.item.net_currency_unit,4) }}
          </template>
          <template #cell(currency_invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.currency_invoice_total,4) }}
          </template>
          <template #cell(tl_invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.tl_invoice_total,4) }}
          </template>
          <template #cell(net_currency_total)="data">
            {{ $staticParams.formatCurrency(data.item.net_currency_total,4) }}
          </template>
          <template #cell(net_tl_total)="data">
            {{ $staticParams.formatCurrency(data.item.net_tl_total,4) }}
          </template>
          <template #cell(currency_rebate)="data">
            {{ $staticParams.formatCurrency(data.item.netcurrency_rebate_tl_total,4) }}
          </template>
          <template #cell(tl_rebate)="data">
            {{ $staticParams.formatCurrency(data.item.tl_rebate,4) }}
          </template>
        </b-table>
        <span v-if="excelImportRows"><strong>{{ excelImportRows.length }}</strong> Adet Kayıt Bulundu</span>
        <b-table
          v-if="excelImportRows.length>0"
          ref="refExcelImportTable"
          class="position-relative"
          :items="excelImportRows"
          responsive
          :fields="importTableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          small
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(list_price)="data">
            {{ $staticParams.formatCurrency(data.item.list_price,4) }}
          </template>
          <template #cell(currency_unit_price)="data">
            {{ $staticParams.formatCurrency(data.item.currency_unit_price,4) }}
          </template>
          <template #cell(tl_unit_price)="data">
            {{ $staticParams.formatCurrency(data.item.tl_unit_price,4) }}
          </template>
          <template #cell(net_currency_unit)="data">
            {{ $staticParams.formatCurrency(data.item.net_currency_unit,4) }}
          </template>
          <template #cell(currency_invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.currency_invoice_total,4) }}
          </template>
          <template #cell(tl_invoice_total)="data">
            {{ $staticParams.formatCurrency(data.item.tl_invoice_total,4) }}
          </template>
          <template #cell(net_currency_total)="data">
            {{ $staticParams.formatCurrency(data.item.net_currency_total,4) }}
          </template>
          <template #cell(net_tl_total)="data">
            {{ $staticParams.formatCurrency(data.item.net_tl_total,4) }}
          </template>
          <template #cell(currency_rebate)="data">
            {{ $staticParams.formatCurrency(data.item.netcurrency_rebate_tl_total,4) }}
          </template>
          <template #cell(tl_rebate)="data">
            {{ $staticParams.formatCurrency(data.item.tl_rebate,4) }}
          </template>
        </b-table>
      </b-overlay>
    </b-modal>
    <product-list-add-new
      :is-add-new-product-sidebar-active.sync="isAddNewProductSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
      @addIsSuccess="addIsSuccess"
    />

    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>
          <b-col>
            <b-table-simple

              small
              caption-top
              responsive
            >

              <b-thead>

                <b-tr>
                  <b-th>{{ $t('Yıl') }}</b-th>
                  <b-th>{{ $t('Ay') }}</b-th>
                  <b-th>{{ $t('Toplam Kayıt') }}</b-th>
                  <b-th>{{ $t('Son yükleme Zamanı') }}</b-th>
                  <b-th />

                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(data, index) in monthYearlyData"
                  :key="index"
                >
                  <b-td>{{ data.year }}</b-td>
                  <b-td>{{ $staticParams.getMonthName(data.month) }}</b-td>
                  <b-td>{{ data.row_count }}</b-td>
                  <b-td>
                    <b-badge
                      v-if="data.last_insert_date!=null"
                      variant="success"
                    >
                      {{ $format_date(data.last_insert_date) }} ({{ $moment(data.last_insert_date).fromNow() }})
                    </b-badge>
                    <b-badge
                      v-else
                      variant="danger"
                    >
                      Dosya Yüklenmedi
                    </b-badge>
                  </b-td>
                  <b-td><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.records-modal
                    variant="primary"
                    size="sm"
                    @click="selectYearMonth(data)"
                  >
                    Detaylar
                  </b-button></b-td>
                </b-tr>

              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BModal, BFormFile, BFormGroup, BTableSimple, BThead, BTbody, BTr, BTd, BTh, VBModal, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import readXlsxFile from 'read-excel-file'
import useJwt from '@/auth/jwt/useJwt'
import useProductsList from './useProductList'
import productStoreModule from '../productStoreModule'
import ProductListAddNew from './ProductListAddNew.vue'

const { getJsDateFromExcel } = require('excel-date-to-js')

export default {
  components: {
    ProductListAddNew,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BBadge,
    BModal,
    BFormInput,
    BButton,
    BTable,
    BFormFile,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      monthYearlyData: [],
      excelImportRows: [],
      uploadFailedRows: [],
      importTableColumns: [

        { key: 'invoice_type', label: 'Fatura Türü', sortable: true },
        { key: 'buy_date', label: 'Alım Tarihi', sortable: false },
        { key: 'invoice_number', label: 'Fatura No', sortable: false },
        { key: 'category', label: 'Kategori', sortable: false },
        { key: 'product_name', label: 'Ürün Adı', sortable: false },
        { key: 'currency', label: 'Döviz', sortable: false },
        { key: 'exchange_rate', label: 'Döviz Kuru', sortable: false },

        { key: 'piece', label: 'Miktar', sortable: false },
        { key: 'currency_unit_price', label: 'Döviz Birim Fiyat', sortable: false },
        { key: 'tl_unit_price', label: 'Tl Döviz Fiyat', sortable: false },
        { key: 'invoice_total', label: 'Fatura Toplam', sortable: false },
        { key: 'description', label: 'Açıklama', sortable: false },
      ],
    }
  },
  computed: {
    targetMonts() {
      const arr = [{ number: 0, name: 'Tümü' }]
      this.$staticParams.monthNames.forEach(val => {
        arr.push(val)
      })
      return arr
    },
  },
  created() {
    this.getMonthYearlyData()
  },
  methods: {
    selectYearMonth(data) {
      this.targetMonth = data.month
      this.targetYear = data.year
      setTimeout(() => this.$refs.refProductListTable.refresh(), 2000)
    },
    getMonthYearlyData() {
      store
        .dispatch('app-other-expense/fetchMonthYearlyData')
        .then(response => {
          this.monthYearlyData = response.data.data
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    toExcel() {
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/other-expense?targetMonth=${this.targetMonth}&targetYear=${this.targetYear}&list_type=excel&token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    resetImportModal() {
      this.excelImportRows = []
    },
    startImport(bvModalEvt) {
      this.importModalOverlayShow = true
      bvModalEvt.preventDefault()
      store.dispatch('app-other-expense/addProductsBulk', { target_month: this.targetMonth, target_year: this.targetYear, data_arr: this.excelImportRows })
        .then(response => {
          this.$refs.refProductListTable.refresh()
          this.uploadFailedRows = response.data.data
          if (this.uploadFailedRows.length <= 0) {
            this.$nextTick(() => {
              this.$refs['import-modal'].toggle('#import-modal-toggle-btn')
            })
          }

          this.showSuccessMessage()
          this.importModalOverlayShow = false
        }).catch(() => {
          this.importModalOverlayShow = false
          this.showErrorMessage()
        })

      // Prevent modal from closing

      // Trigger submit handler
    },
    onFileChange(event) {
      this.uploadFailedRows = []
      const xlsxfile = event.target.files ? event.target.files[0] : null
      this.excelImportRows = []
      readXlsxFile(xlsxfile).then(rows => {
        let counter = 0
        rows.forEach(val => {
          // eslint-disable-next-line no-plusplus
          counter++
          if (counter >= 2) {
            const importData = {
              invoice_type: val[0],
              buy_date: this.excelDateToJSDate(val[1]),
              invoice_number: val[2],
              category: val[3],
              product_name: val[4],
              currency: val[5],
              exchange_rate: val[6],
              piece: val[7],
              currency_unit_price: val[8],
              tl_unit_price: val[9],
              invoice_total: val[10],
              description: val[11],
            }

            this.excelImportRows.push(importData)
          }
        })
      })
    },
    addIsSuccess(id) {
      this.editProductId = id
      this.isEditProductSidebarActive = true
    },
    excelDateToJSDate(excelDate) { //
      try {
        const date = getJsDateFromExcel(excelDate)
        // const date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000))
        const convertedDate = date.toISOString().split('T')[0]
        return convertedDate
      } catch (error) {
        const date = new Date(excelDate)
        // const date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000))
        const convertedDate = date.toISOString().split('T')[0]
        return convertedDate
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-other-expense'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const editProductId = ref(0)
    const isAddNewProductSidebarActive = ref(false)
    const isEditProductSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,
      targetYear,
      targetMonth,
      // UI

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useProductsList()

    return {
      targetYear,
      targetMonth,
      // Sidebar
      isAddNewProductSidebarActive,
      isEditProductSidebarActive,
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      editProductId,
      isSortDirDesc,
      refProductListTable,
      refetchData,

      // Filter
      avatarText,

      // UI

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
