import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addProductsBulk(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/other-expense-add-bulk', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/other-expense', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/p-products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMonthYearlyData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/other-expense-monthly-data', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/p-products', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/p-products/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // categories
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/p-productcategories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/p-productcategories', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/p-productcategories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/p-productcategories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // brands
    fetchBrands(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/p-brands', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBrand(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/p-brands', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBrand(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/p-brands/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBrand(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/p-brands/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // tax types
    addTaxType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/taxtypes', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTaxType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/taxtypes/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTaxType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/taxtypes/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // product stores
    suggestSeller(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/suggest-p-sellers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStocks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/p-products-stocks', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStockData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/p-products-stocks', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStockData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/p-products-stocks/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStockData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/p-products-stocks/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
